.productTable table{
    border: 1px solid black;
}

.productTable table td{
    border: 1px solid black;
    padding: 5px;
}

footer nav li > div{
    display: none !important;
    opacity: 0;
}


  
  .border-black {
    border-color: #f15722;
    border-bottom-width: 4px;
  }