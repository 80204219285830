@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul> li{
  margin-left: 0px !important;
}


@media(max-width: 720px){
.hideheight{
  max-height: 0px;
  transition: 0.7s;
  overflow: hidden;
}

.maxheight{
  max-height: 1000px;
  transition: 0.7s;
}
}

.smogbackground:before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color:rgba(0, 0, 0, .7);
}